import { CognitoUserPool } from "amazon-cognito-identity-js";
import cognitoConfig from "./cognito-config";
import AWS from "aws-sdk";

const poolData = {
    UserPoolId: "us-east-1_hcAVmXBkK",
    ClientId: "1qarkk9lqm8uiqj4vhjf7q41er",
};

const userPool = new CognitoUserPool(poolData);

AWS.config.region = "us-east-1"

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                    return;
                }

                if (session.isValid()) {
                    resolve(cognitoUser);
                } else {
                    reject("Invalid session");
                }
            });
        } else {
            reject("No user found");
        }
    });
};

